import { type ReactNode, useEffect } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'

import { ClockFormatContext } from '@eventtemple/eventtemple-ui'
import ThemeProvider from '@repo/theme'
import { SnackbarProvider } from 'notistack'

import { hotjar } from 'react-hotjar'

import use24HourClock from 'hooks/use24HourClock'
import useDateFnsLocale from 'hooks/useDateFnsLocale'

export const MAX_SNACKS = 3

interface UiProviderProps {
  children: ReactNode
}

const UiProvider = ({ children }: UiProviderProps): React.JSX.Element => {
  const currentLocale = useDateFnsLocale()
  const use24HourTime = use24HourClock()

  // Initialize HotJar
  useEffect(() => {
    const HJID = parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID ?? '0')
    const HJSV = parseInt(process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION ?? '0')

    if (HJID && HJSV) hotjar.initialize({ id: HJID, sv: HJSV })
  }, [])

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLocale}>
        <ClockFormatContext.Provider value={use24HourTime}>
          <SnackbarProvider maxSnack={MAX_SNACKS}>{children}</SnackbarProvider>
        </ClockFormatContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default UiProvider
