import { useCallback, useMemo, useState } from 'react'

import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'

import { useTranslations } from 'next-intl'

import { confirmable } from 'react-confirm'

import type { ButtonProps, DialogProps } from '@mui/material'
import type { ChangeEvent, ReactNode } from 'react'
import type { ConfirmDialogProps } from 'react-confirm'

interface ConfirmDialogButtonProps {
  agreeButtonProps: {
    label?: string | ReactNode
  } & ButtonProps
  dismissButtonProps: {
    label?: string | ReactNode
  } & ButtonProps
}
interface InputProps extends Omit<DialogProps, 'open'> {
  title?: string
  message?: string
  contentBody?: ReactNode
  confirmTextValue?: 'delete' | 'submit'
  agreeButtonProps?: ConfirmDialogButtonProps['agreeButtonProps']
  dismissButtonProps?: ConfirmDialogButtonProps['dismissButtonProps']
  task?: () => Promise<unknown>
}

const ConfirmInputDialog = ({
  title,
  message,
  contentBody,
  confirmTextValue,
  agreeButtonProps,
  dismissButtonProps,
  task,
  /** show, proceed, and cancel are coming from react-confirm */
  show,
  proceed,
  cancel,
  ...props
}: ConfirmDialogProps<InputProps, boolean>) => {
  const t = useTranslations()
  const [isProceeding, setIsProceeding] = useState(false)

  const [confirmText, setConfirmText] = useState('')

  const confirmTextMessage = useMemo(
    () => t('confirmText', { val: confirmTextValue }),
    [confirmTextValue, t]
  )

  const contentHtml = useMemo(() => ({ __html: message || '' }), [message])

  const setConfirmTextValue = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setConfirmText(e.target.value)
    },
    []
  )

  const buttonDisabled = confirmTextValue && confirmText != confirmTextValue

  const onAgree = useCallback(async () => {
    try {
      setIsProceeding(true)

      if (task) {
        await task()
      }

      proceed(true)
    } catch (error) {
      cancel(error)
    } finally {
      setIsProceeding(false)
    }
  }, [proceed, cancel, task])

  const onDismiss = useCallback(() => {
    proceed(false)
  }, [proceed])

  return (
    <Dialog open={show} {...props}>
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
        {title ?? t('confirm')}
        <IconButton onClick={onDismiss}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {message && (
            <Grid mb={2} size={12}>
              <DialogContentText dangerouslySetInnerHTML={contentHtml} />
            </Grid>
          )}

          {contentBody && (
            <Grid mb={2} size={12}>
              {contentBody}
            </Grid>
          )}

          {confirmTextValue && (
            <>
              <Grid mb={2} size={12}>
                <Typography variant="subtitle1">{confirmTextMessage}</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  hiddenLabel
                  value={confirmText}
                  onChange={setConfirmTextValue}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button {...dismissButtonProps} onClick={onDismiss}>
          {dismissButtonProps?.label ?? t('cancel')}
        </Button>
        <LoadingButton
          loading={isProceeding}
          {...agreeButtonProps}
          disabled={buttonDisabled}
          variant="contained"
          onClick={onAgree}>
          {agreeButtonProps?.label ?? t('agree')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default confirmable(ConfirmInputDialog)
