'use client'

import { memo, useEffect } from 'react'

const NROptions = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] }
  },
  loader_config: {
    accountID: process.env.NEXT_PUBLIC_NEW_RELIC_ACCOUNT_ID,
    licenseKey: process.env.NEXT_PUBLIC_NEW_RELIC_LICENSE_KEY,
    applicationID: process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID
  },
  info: {
    accountID: process.env.NEXT_PUBLIC_NEW_RELIC_ACCOUNT_ID,
    licenseKey: process.env.NEXT_PUBLIC_NEW_RELIC_LICENSE_KEY,
    applicationID: process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID,
    sa: 1
  }
}

const shouldLoadNewRelic =
  typeof window !== 'undefined' && // Ensure we're in a browser environment, SSR is not supported.
  process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID &&
  process.env.NEXT_PUBLIC_VERCEL_ENV // Ensure we're not in a local development environment

const NewRelicAgent = (): React.JSX.Element | null => {
  useEffect(() => {
    const loadNR = async () => {
      const { BrowserAgent } = await import('@newrelic/browser-agent/loaders/browser-agent')
      new BrowserAgent(NROptions)
    }

    if (shouldLoadNewRelic) loadNR()
  }, [])

  return null
}

export default memo(NewRelicAgent)
