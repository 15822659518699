import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/client/src/providers/BaseProviders.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/client/src/styles/application.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.1.0_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-_tapqrlfef2bx3b47f36mcvy6um/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.1.0_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-pl_vrrwg35kdmadflpvup5itzrtma/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.26.1_next@15.1.0_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@_an42rxdmmqula5vrlbrsrlw3eu/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.0_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_babel-plugi_ljrjreaws6cuav3bkerf5hvzyi/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Barlow\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"swap\",\"fallback\":[\"Public Sans\",\"sans-serif\"]}],\"variableName\":\"barlow\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/new-relic-agent/NewRelicAgent.tsx");
