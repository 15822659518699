import { useEffect, useRef } from 'react'

import { Dialog, DialogContent, DialogContentText, Typography } from '@mui/material'

import { type ConfirmDialogProps, confirmable } from 'react-confirm'

import type { DialogProps } from '@mui/material'

interface AsyncCommandDialogProps extends Omit<DialogProps, 'open'> {
  message: string
  task: () => Promise<unknown>
}

const AsyncTaskDialog = ({
  message,
  task,
  show,
  proceed,
  cancel,
  ...props
}: ConfirmDialogProps<AsyncCommandDialogProps, unknown>) => {
  const hasRun = useRef(false)

  useEffect(() => {
    const executeTask = async () => {
      // Prevent double execution
      if (hasRun.current) return

      hasRun.current = true

      try {
        const result = await task()

        proceed(result)
      } catch (error) {
        cancel(error)
      }
    }

    executeTask()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog open={show} {...props} maxWidth="xs" fullWidth>
      <DialogContent sx={{ p: 4 }}>
        <DialogContentText>
          <Typography variant="h5">{message}</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default confirmable(AsyncTaskDialog)
