import { createConfirmationCreater, createMountPoint, createReactTreeMounter } from 'react-confirm'

import AsyncTaskDialog from 'components/common/Dialog/AsyncTaskDialog'
import ConfirmInputDialog from 'components/common/Dialog/ConfirmInputDialog'
import PerformPrintDialog from 'components/common/Dialog/PerformPrintDialog'

const mounter = createReactTreeMounter()

export const createConfirmation = createConfirmationCreater(mounter)
export const ReactConfirmMountPoint = createMountPoint(mounter)

export const asyncTaskDialog = createConfirmation(AsyncTaskDialog)
export const confirmInputDialog = createConfirmation(ConfirmInputDialog)
export const performPrintDialog = createConfirmation(PerformPrintDialog)
