'use client'

import QueryProvider from 'providers/QueryProvider'
import UiProvider from 'providers/UiProvider'

import { ReactConfirmMountPoint } from 'utils/react-confirm'

import type { ReactNode } from 'react'

const BaseProviders = ({ children }: { children: ReactNode }) => (
  <QueryProvider>
    <UiProvider>
      <ReactConfirmMountPoint />
      {children}
    </UiProvider>
  </QueryProvider>
)

export default BaseProviders
